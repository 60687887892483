var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"d-flex justify-center",attrs:{"fluid":"","fill-height":""}},[_c('div',{staticClass:"ant-glass-background sign-in-card"},[_c('div',{staticClass:"ant-card-header"},[_vm._v("Account Information")]),_c('transition',{attrs:{"name":"fade"}},[_c('div',[_c('v-form',{ref:"inviteSignUpForm",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"required":"","rules":[_vm.rules.required],"label":"First name"},model:{value:(_vm.userInfo.firstName),callback:function ($$v) {_vm.$set(_vm.userInfo, "firstName", $$v)},expression:"userInfo.firstName"}}),_c('v-text-field',{attrs:{"required":"","rules":[_vm.rules.required],"label":"Last name"},model:{value:(_vm.userInfo.lastName),callback:function ($$v) {_vm.$set(_vm.userInfo, "lastName", $$v)},expression:"userInfo.lastName"}}),_c('v-text-field',{attrs:{"type":_vm.passwordFieldType,"label":"Password","minlength":"8","rules":[_vm.rules.required, _vm.rules.length],"append-icon":_vm.passwordFieldType === 'password'
                ? 'mdi-eye-outline'
                : 'mdi-eye-off-outline'},on:{"click:append":function($event){_vm.passwordFieldType === 'password'
                ? (_vm.passwordFieldType = 'text')
                : (_vm.passwordFieldType = 'password')}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"type":_vm.passwordConfirmFieldType,"label":"Confirm password","rules":[_vm.rules.required, _vm.isPasswordsMatch],"append-icon":_vm.passwordConfirmFieldType === 'password'
                ? 'mdi-eye-outline'
                : 'mdi-eye-off-outline'},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onSubmit.apply(null, arguments)},"click:append":function($event){_vm.passwordConfirmFieldType === 'password'
                ? (_vm.passwordConfirmFieldType = 'text')
                : (_vm.passwordConfirmFieldType = 'password')}},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}})],1),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"flex-grow-1 mr-2",attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" CONNECT ")])],1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }