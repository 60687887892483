<template>
  <v-container fluid fill-height class="d-flex justify-center">
    <div class="ant-glass-background sign-in-card">
      <div class="ant-card-header">Account Information</div>
      <transition name="fade">
        <div>
          <v-form ref="inviteSignUpForm" @submit.prevent="onSubmit">
            <v-text-field
              v-model="userInfo.firstName"
              required
              :rules="[rules.required]"
              label="First name"
            />
            <v-text-field
              v-model="userInfo.lastName"
              required
              :rules="[rules.required]"
              label="Last name"
            />
            <v-text-field
              v-model="password"
              :type="passwordFieldType"
              label="Password"
              minlength="8"
              :rules="[rules.required, rules.length]"
              :append-icon="
                passwordFieldType === 'password'
                  ? 'mdi-eye-outline'
                  : 'mdi-eye-off-outline'
              "
              @click:append="
                passwordFieldType === 'password'
                  ? (passwordFieldType = 'text')
                  : (passwordFieldType = 'password')
              "
            />
            <v-text-field
              v-model="passwordConfirm"
              :type="passwordConfirmFieldType"
              label="Confirm password"
              :rules="[rules.required, isPasswordsMatch]"
              :append-icon="
                passwordConfirmFieldType === 'password'
                  ? 'mdi-eye-outline'
                  : 'mdi-eye-off-outline'
              "
              @keydown.enter="onSubmit"
              @click:append="
                passwordConfirmFieldType === 'password'
                  ? (passwordConfirmFieldType = 'text')
                  : (passwordConfirmFieldType = 'password')
              "
            />
          </v-form>
          <div class="d-flex">
            <v-btn
              :loading="loading"
              class="flex-grow-1 mr-2"
              color="primary"
              @click="onSubmit"
            >
              CONNECT
            </v-btn>
          </div>
        </div>
      </transition>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { userInviteAccept } from '@/services/api/user.api';

export default {
  data: () => ({
    userInfo: {
      firstName: '',
      lastName: '',
    },
    password: '',
    passwordConfirm: '',
    loading: false,
    rules: {
      required: (value) => !!value || 'Required.',
      length: (value) => {
        if (!/^(?=.*[A-Z]).*$/.test(value)) {
          return 'Password must have at least one uppercase character.';
        }
        if (!/^(?=.*[0-9]).*$/.test(value)) {
          return 'Password must contain at least one Digit.';
        }
        if (!/^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/.test(value)) {
          return 'Password must contain at least one special symbol.';
        }
        if (!/^(?=.*[a-z]).*$/.test(value)) {
          return 'Password must have at least one lowercase character.';
        }
        return value.length > 7 || 'At least 8 characters.';
      },
    },
    passwordFieldType: 'password',
    passwordConfirmFieldType: 'password',
  }),
  computed: {
    ...mapGetters(['authenticatedUser']),
    isPasswordsMatch() {
      return (
        this.password === this.passwordConfirm || 'The passwords do not match'
      );
    },
  },
  methods: {
    onSubmit() {
      if (this.$refs.inviteSignUpForm.validate()) {
        const { token } = this.$route.params;
        const payload = {
          firstname: this.userInfo.firstName,
          lastname: this.userInfo.lastName,
          password: this.password,
          password_confirm: this.passwordConfirm,
        };
        if (token) {
          payload.token = token;
        }
        this.loading = true;
        userInviteAccept(payload)
          .then(() => {
            this.loading = false;
            this.$store.commit('showNotification', {
              content: `Profile activated`,
              color: 'success',
            });
            this.$router.push('/login');
          })
          .catch((err) => {
            this.loading = false;
            console.error(err);
            this.$store.commit('showNotification', {
              content: err.message,
              color: 'error',
            });
            this.$router.push('/');
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sign-in-card {
  padding: 25px 100px;
  @media (max-width: 576px) {
    padding: 25px;
  }
  .ant-card-header {
    text-align: center;
    font-size: 30px;
    color: var(--v-primary-base);
  }
}
</style>
